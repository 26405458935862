body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hand {
  cursor: pointer;
}

.md-input-no-label > input,
.md-input-no-label > select {
  padding-top: 1px !important;
}

.md-input-dense {
  height: 52px !important;
}

.md-input-no-label.md-input-dense {
  height: 40px !important;
}

.md-input-wrapper.md-input-dense > .md-input-icon {
  top: 7px !important;
}

.md-input-wrapper.md-input-dense > input[type="date"] span.md-input-icon {
  top: 13px !important;
}

.canvas-container {
  width: 100%;
  height: 280px;
  margin: 0 auto;
  background-color: #fff;
}

.sigPad {
  width: 100%;
  height: 100%;
}
.datatable-header {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  flex-direction: inherit;
  justify-content: flex-start;
}
.datatable-row {
  white-space:nowrap;
}
.datatable-default-order-icon {
  opacity: 0;
}

.datatable-header:hover > .datatable-default-order-icon {
  opacity: 0.5;
}
.uk-list > li {
  padding: 16px;
}
.uk-list-divider > :nth-child(n + 2) {
  margin-top: 0px !important;
  padding-bottom: 10px;
}
.pointer{
  cursor: pointer;
}